import {Action} from '@ngrx/store';
import {About} from '../models/about';

export const GET_ALL_ABOUT = '[ABOUT] get all';
export const SET_ALL_ABOUT = '[ABOUT] set all';
export const GET_ABOUT_BY_ID = '[ABOUT] get about by id';
export const GET_ABOUT_IMAGE = '[ABOUT] get about image';
export const CREATE_ABOUT = '[ABOUT] create about';
export const UPDATE_ABOUT = '[ABOUT] update about';
export const DELETE_ABOUT = '[ABOUT] delete about';


export const GET_ALL_ABOUT_SUCCESS = '[ABOUT] get all success';


export class GetAllAbout implements Action {
  readonly type = GET_ALL_ABOUT;

  constructor() {
  }
}

export class GetAllAboutSuccess implements Action {
  readonly type = GET_ALL_ABOUT_SUCCESS;

  constructor(public payload: About[]) {
  }
}

export class GetAboutById implements Action {
  readonly type = GET_ABOUT_BY_ID;

  constructor(public payload: string) {
  }
}

export class GetAboutImage implements Action {
  readonly type = GET_ABOUT_IMAGE;

  constructor(public payload: string) {
  }
}

export class CreateAbout implements Action {
  readonly type = CREATE_ABOUT;

  constructor(public payload: About) {
  }
}

export class UpdateAbout implements Action {
  readonly type = UPDATE_ABOUT;

  constructor(public payload: About) {
  }
}

export class DeleteAbout implements Action {
  readonly type = DELETE_ABOUT;

  constructor(public payload: About) {
  }
}


export type Action = GetAllAbout | GetAllAboutSuccess | GetAboutById | GetAboutImage | CreateAbout | UpdateAbout | DeleteAbout;
