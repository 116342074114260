import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '../../services/language.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as languageAction from '../../store/actions/language';
import * as rootStore from '../../store/reducers';
import {language, english} from '../../store/actions/language';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit, OnDestroy {

  @Input('thin') thin = false;

  languageForm: FormGroup;
  languages = [];

  private subs: Subscription[] = [];

  constructor(private fb: FormBuilder,
              private store: Store<rootStore.State>,
              private _languages: LanguageService) {
    this.languageForm = fb.group({
      name: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.subs.push(
      this._languages.getAll().subscribe(
        (languages: any) => {
          this.languages = languages;
        }, err => {
          console.log(err);
        }
      )
    );
    this.subs.push(
      this.languageForm.controls['name'].valueChanges.subscribe(
        newValue => {
          this.store.dispatch(new languageAction.SetLanguage(newValue));
        }
      )
    );
    const currentLanguage = localStorage.getItem(language);
    if (currentLanguage) {
      this.languageForm.controls['name'].setValue(currentLanguage);
    } else {
      this.languageForm.controls['name'].setValue(english);
      localStorage.setItem(language, english);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

}
