import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild('curtain') curtain;
  @ViewChild('root') root;
  @ViewChild('modal') modal: ElementRef;

  registerForm: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder,
              private _user: UserService,
              private router: Router,
              private renderer: Renderer2) {
    this.registerForm = fb.group({
      phone: [''],
      genre: [''],
      email: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      country: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(1024)]],
      last_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      first_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      re_password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(1024)]]
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.registerForm.controls['re_password'].valueChanges.subscribe(
        data => {
          if (data !== this.registerForm.controls['password'].value) {
            this.registerForm.controls['re_password'].setErrors({'notMatch': 'Password does not match'});
          }
        }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  doRegister() {
    if (this.registerForm.valid && this.registerForm.controls['password'].value === this.registerForm.controls['re_password'].value) {
      this.subscriptions.push(
        this._user.register({
          email: this.registerForm.controls['email'].value,
          genre: this.registerForm.controls['genre'].value,
          phone: this.registerForm.controls['phone'].value,
          country: this.registerForm.controls['country'].value,
          last_name: this.registerForm.controls['last_name'].value,
          first_name: this.registerForm.controls['first_name'].value,
          password: this.registerForm.controls['password'].value
        }).subscribe(
          data => {
            this.openModal();
          }, err => {
            console.log(err);
          }
        )
      );
    }
  }

  async fbLogin() {
    const result = await this._user.fbLogin();
    if (result) {
      this.router.navigate(['/user/profile'], {fragment: 'info'});
    } else {
      // this.error = true;
    }
  }

  openModal() {
    this.renderer.setStyle(this.modal.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'block');
    this.renderer.addClass(this.getBody(), 'modal-open');
  }

  private getBody() {
    let body = this.root.nativeElement.parentElement;
    while (body.parentElement !== null && body.parentElement.parentElement !== null) {
      body = body.parentElement;
    }
    return body;
  }
}
