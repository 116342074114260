import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-reset-confirm',
  templateUrl: './reset-confirm.component.html',
  styleUrls: ['./reset-confirm.component.scss']
})
export class ResetConfirmComponent implements OnInit {

  notMatch = false;

  passwordForm: FormGroup;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private _user: UserService) {
    this.passwordForm = fb.group({
      password: ['', [Validators.required, Validators.minLength(5)]],
      repeat: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  ngOnInit() {

  }

  saveNewPass() {
    if (this.passwordForm.valid && this.passwordForm.controls['password'].value === this.passwordForm.controls['repeat'].value) {
      this._user.reset(
        this.route.snapshot.queryParams.id,
        this.route.snapshot.queryParams.token,
        this.passwordForm.controls['repeat'].value
      ).subscribe(
        success => {
          this.router.navigate(['/', 'login']);
        }, err => {
          console.log(err);
        }
      );
    } else {
      this.notMatch = true;
    }
  }
}
