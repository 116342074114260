import {Component, OnDestroy, OnInit} from '@angular/core';
import {PricesService} from '../../services/prices.service';
import {Subscription} from 'rxjs';
import * as rootStore from '../../store/reducers';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnDestroy {

  pricesList = [];

  language = '';

  private subscriptions: Subscription[] = [];

  constructor(private _prices: PricesService,
              private store: Store<rootStore.State>) {
  }

  ngOnInit() {
    this.loadPriceList();
    this.subscriptions.push(
      this.store.select(rootStore.getCurrentLanguage).subscribe(
        language => {
          this.language = language;
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTextFromTranslation(id: string) {
    return this.pricesList.find(p => p._id === id).translations.find(t => t.language === this.language).text;
  }

  getTitleFromTranslation(id: string) {
    return this.pricesList.find(p => p._id === id).translations.find(t => t.language === this.language).title;
  }

  private loadPriceList() {
    this.subscriptions.push(this._prices.getAll().subscribe(
      (data: any) => {
        if (data) {
          this.pricesList = [];
          this.pricesList = data;
        }
      },
      error1 => {
        console.log(error1);
      }
    ));
  }
}
