import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RegisterComponent} from './components/register/register.component';
import {ServicesComponent} from './components/services/services.component';
import {LoginComponent} from './components/login/login.component';
import {ContactComponent} from './components/contact/contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PriceComponent} from './components/price/price.component';
import {SpecialComponent} from './components/special/special.component';
import {DndModule} from 'ngx-drag-drop';
import {DownloadComponent} from './components/download/download.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AngularFileUploaderModule} from 'angular-file-uploader';
import {PhotoSliderComponent} from './components/photo-slider/photo-slider.component';
import {MorePhotosComponent} from './components/more-photos/more-photos.component';
import {SharedModule} from './shared/shared.module';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store/reducers';
import {AboutEffects} from './store/effects/about';
import {EffectsModule} from '@ngrx/effects';
import {FaqEffects} from './store/effects/faq';
import {MainComponent} from './components/main/main.component';
import {CommentComponent} from './components/comment/comment.component';
import {VerifyComponent} from './components/verify/verify.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DownloadTextComponent } from './components/download-text/download-text.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetConfirmComponent } from './components/reset-confirm/reset-confirm.component';

import {SlideshowModule} from 'ng-simple-slideshow';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    ServicesComponent,
    LoginComponent,
    ContactComponent,
    PriceComponent,
    SpecialComponent,
    DownloadComponent,
    PhotoSliderComponent,
    MorePhotosComponent,
    MainComponent,
    CommentComponent,
    VerifyComponent,
    NotFoundComponent,
    DownloadTextComponent,
    ConfirmComponent,
    ResetPasswordComponent,
    ResetConfirmComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    DndModule,
    AngularFileUploaderModule,
    SharedModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot(reducers),
    SlideshowModule,
    EffectsModule.forRoot([AboutEffects, FaqEffects]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
