import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExampleService {

  constructor(private http: HttpClient) {
  }

  public getAll(priceUrl?: string) {
    return this.http.get(`/api/examples/all${priceUrl ? '/' + priceUrl : ''}`);
  }

  public create(priceUrl: string) {
    return this.http.post('/api/examples', {
      priceUrl: priceUrl
    });
  }

  public addSmallImage(id: string, blobData) {
    const request = new FormData();

    request.append('image', blobData);
    request.append('id', id);

    return this.http.post('/api/examples/small', request);
  }

  public addFullImage(id: string, blobData) {
    const request = new FormData();

    request.append('image', blobData);
    request.append('id', id);

    return this.http.post('/api/examples/full', request);
  }

  public updatePriceUrl(id: string, priceUrl: string) {
    return this.http.put('/api/examples', {
      id: id,
      priceUrl: priceUrl
    });
  }

  public delete(id: string) {
    return this.http.delete(`/api/examples/${id}`);
  }
}
