import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {PricesService} from '../../services/prices.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OrderService} from '../../services/order.service';
import {ToastrService} from 'ngx-toastr';
import {TranslatingService} from '../../services/translating.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-download-text',
  templateUrl: './download-text.component.html',
  styleUrls: ['./download-text.component.scss']
})
export class DownloadTextComponent implements OnInit, OnDestroy {

  @ViewChild('curtain') curtain;
  @ViewChild('root') root;

  @ViewChild('downloadModal') downloadModal: ElementRef;

  sum = 0;
  step = 1;

  logined = false;

  prices = [];

  addForm: FormGroup;

  private orderID = '';
  private subs: Subscription[] = [];

  constructor(private renderer: Renderer2,
              private router: Router,
              private toaster: ToastrService,
              private translation: TranslatingService,
              private _order: OrderService,
              private _user: UserService,
              private fb: FormBuilder,
              private _prices: PricesService) {
    this.logined = this._user.isLoged();
    this.addForm = fb.group({
      reference_url: ['', Validators.required],
      description: ['', Validators.required],
      source_url: ['', Validators.required],
      hurry: [false, Validators.required]
    });
  }

  ngOnInit() {
    this.subs.push(
      this._prices.getAll().subscribe(
        (prices: any) => {
          this.prices = prices;
          prices.forEach(
            price => {
              this.addForm.addControl(price.urlFragment, new FormControl(0, Validators.required));
              this.addForm.controls[price.urlFragment].valueChanges.subscribe(
                (number: number) => {
                  if (number < 0) {
                    this.addForm.controls[price.urlFragment].setValue(0);
                  }
                }
              );
            }
          );
        }, err => {
          console.log(err);
        }
      )
    );


    this.addForm.controls['hurry'].valueChanges.subscribe(
      (data: boolean) => {
        let newPrice = 0;
        if (data) {
          this.prices.forEach(price => {
            newPrice += (price.price * this.addForm.controls[price.urlFragment].value * 1.5);
          });
        } else {
          this.prices.forEach(price => {
            newPrice += (price.price * this.addForm.controls[price.urlFragment].value);
          });
        }
        this.sum = newPrice;
      }
    );
  }

  test(event) {
    this.sum = 0;
    this.prices.forEach(
      price => {
        this.sum += (this.addForm.controls[price.urlFragment].value * price.price);
      }
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  increasePriceAmount(name: string, price: number) {
    this.addForm.controls[name].setValue(this.addForm.controls[name].value + 1);
    this.sum += price;
  }

  decreasePriceAmount(name: string, price: number) {
    if (this.addForm.controls[name].value > 0) {
      this.addForm.controls[name].setValue(this.addForm.controls[name].value - 1);
      this.sum -= price;
    }
  }

  nextStep(step: number) {
    if (this.step === step) {
      this.step++;
    }
  }

  prevStep(step: number) {
    if (this.step === step) {
      this.step--;
    }
  }

  // DOWNLOAD MODAL

  openDownloadModal() {
    const data = {
      source_url: this.addForm.controls['source_url'].value,
      reference_url: this.addForm.controls['reference_url'].value,
      description: this.addForm.controls['description'].value,
    };
    data['items'] = [];
    this.prices.forEach(
      price => {
        data['items'].push({
          price: price.price,
          price_name: price.title,
          count: this.addForm.controls[price.urlFragment].value
        });
        data[price.urlFragment] = this.addForm.controls[price.urlFragment].value;
      }
    );
    this.subs.push(this._order.createdExtendedOrder(this._user.getID(), this.addForm.controls['hurry'].value,
      data).subscribe(
      (success: any) => {
        this.orderID = success.id;
        this.renderer.setStyle(this.downloadModal.nativeElement, 'display', 'block');
        this.renderer.setStyle(this.curtain.nativeElement, 'display', 'block');
        this.renderer.addClass(this.getBody(), 'modal-open');
      }, err => {
        this.toaster.error(this.translation.translate('download-text.toast.error'));
        console.log(err);
      })
    );
  }

  closeDownloadModal(redirect: boolean) {
    this.renderer.setStyle(this.downloadModal.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'none');
    this.renderer.removeClass(this.getBody(), 'modal-open');
    if (redirect === true) {
      this._order.notifyAboutNewOrder(this.orderID).subscribe(
        success => {
        }, error1 => {
          console.log(error1);
        }
      );
      this.orderID = '';
      this.router.navigate(['', 'download-confirm']);
    }
  }

  private getBody() {
    let body = this.root.nativeElement.parentElement;
    while (body.parentElement !== null && body.parentElement.parentElement !== null) {
      body = body.parentElement;
    }
    return body;
  }
}
