import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ContactService} from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;

  constructor(private fb: FormBuilder,
              private toaster: ToastrService,
              private _contact: ContactService) {
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(3)]],
      text: ['', Validators.required]
    });
  }

  submit() {
    if (this.contactForm.valid) {
      this._contact.sendContactMail({
        mail: this.contactForm.controls['email'].value,
        name: this.contactForm.controls['name'].value,
        phone: this.contactForm.controls['phone'].value,
        text: this.contactForm.controls['text'].value
      }).subscribe(
        success => {
          this.toaster.success('Сообщение отправлено');
        }, err => {
          console.log(err);
          this.toaster.error('Не удалось отправить сообщение');
        }
      );
    }
  }
}
