import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppTabSelectedEvent} from '../../../models/app.tab.selected.event';

@Component({
  selector: 'app-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss']
})
export class TabHeaderComponent implements OnInit {

  @Input('tabContentId') tabContentId = '';
  @Output() select: EventEmitter<AppTabSelectedEvent> = new EventEmitter<AppTabSelectedEvent>();

  constructor() { }

  ngOnInit() {
  }

  onTabSelected(tabHeader) {
    this.select.emit({tabEl: tabHeader, tabContentId: this.tabContentId});
  }
}
