import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: string, defaultValue?: any): string {
    switch (value) {
      case 'created':
        return 'Создан';
      case 'accepted':
        return 'Принят';
      case 'in_progress':
        return 'В работе';
      case 'ready':
        return 'На проверке';
      case 'done':
        return 'Выполнен';
      default:
        return 'Создан';
    }
  }

}
