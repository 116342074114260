import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) {
  }

  public getEarnedMoney() {
    return this.http.get('/api/order/earned');
  }

  public getAllOrders(status?: string) {
    return this.http.get(`/api/order/list/${status ? '/' + status : ''}`);
  }

  public getUserOrders() {
    return this.http.get('/api/order/user');
  }

  public getUserOrdersForAdmin(id: string, role: string) {
    return this.http.post('/api/order/userAdmin', {
      id: id,
      role: role
    });
  }

  public getUserOrderByID(id: string) {
    return this.http.get(`/api/order/userOrder/${id}`);
  }

  public getOrderItemImage(id: string) {
    return this.http.get(`/api/order/orderItem/image/${id}`, {responseType: 'blob'});
  }

  /**
   * @param id     - Id of the order to create zip archive of
   * @param source - True and undefined for before items, False for after items
   */
  public getOrderPhotoZip(id: string, source: boolean) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/zip');
    return this.http.post('/api/order/zip',
      {
        id: id,
        source: source
      },
      {
        reportProgress: true,
        headers: headers,
        responseType: 'blob'
      });
  }

  public getOrdersTable(month: number) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/csv');
    return this.http.post('/api/order/excel',
      {
        month: month
      },
      {
        headers: headers,
        responseType: 'blob'
      });
  }

  public createOrder(id: string, hurry: boolean, description?: string) {
    return this.http.post('/api/order', {
      client_id: id,
      hurry: hurry,
      description: description
    });
  }

  public createdExtendedOrder(id: string, hurry: boolean, data) {
    data['client_id'] = id;
    data['hurry'] = hurry;
    return this.http.post('/api/order/extended', data);
  }

  /**
   * Notifies about order that was newly created
   * @param id - Id of newly created order
   */
  public notifyAboutNewOrder(id: string) {
    return this.http.put('/api/order/notifyEmail', {_id: id});
  }

  /**
   * @param  id - Id of the order to be payed
   * @return Observable<PayData>
   *     PayData.data      - LiqPay form "data" field
   *     PayData.signature - LiqPay form "signature" field
   */
  public orderPay(id: string) {
    return this.http.get(`/api/order/pay/${id}`);
  }

  public orderChackPay(id: string) {
    return this.http.get(`/api/order/checkPay/${id}`);
  }

  /**
   * @param id - Id of the order to be set as Payed
   */
  public orderSetPayed(id: string) {
    return this.http.put('/api/order/payed', {
      id: id
    });
  }

  /**
   * @param id     - Id of the order to be changed status
   * @param status - Acceptable statuses - "created", "assigned", "in_progress", "ready", "done"
   */
  public orderSetStatus(id: string, status: string) {
    return this.http.put('/api/order/status', {
      id: id,
      status: status
    });
  }

  public setOrderWorker(orderID: string, workerID: string) {
    return this.http.put('/api/order/worker',
      {
        id: orderID,
        worker_id: workerID
      });
  }

  public purgeFolder(order: string) {
    return this.http.post('/api/order/purgeAfter', {
      _id: order
    });
  }

  public createOrderItem(orderItem, source = true) {
    const request = new FormData();

    request.append('image', orderItem.image);
    request.append('order_id', orderItem.order);
    if (orderItem.price) {
      request.append('price_id', orderItem.price);
    }

    return this.http.post(`/api/order/${source ? 'before' : 'after'}Item`, request);
  }

  public markOrderAsReady(orderID: string) {
    return this.http.put('/api/order/ready', {
      order_id: orderID
    });
  }

  public deleteOrder(id: string) {
    return this.http.delete(`/api/order/${id}`);
  }
}
