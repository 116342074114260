import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient) { }

  public getAll() {
    return this.http.get('/api/translation/all');
  }

  public create(translation: any) {
    return this.http.post('/api/translation', translation);
  }

  public update(id: string, value: string) {
    return this.http.put(`/api/translation/${id}`, {value: value});
  }

  public delete(id: string) {
    return this.http.delete(`/api/translation/${id}`);
  }
}
