import {Action} from '@ngrx/store';
import {Faq} from '../models/faq';

export const GET_ALL_FAQ = '[FAQ] get all';
export const GET_FAQ_BY_ID = '[FAQ] get faq by id';
export const CREATE_FAQ = '[FAQ] create faq';
export const UPDATE_FAQ = '[FAQ] update faq';
export const DELETE_FAQ = '[FAQ] delete faq';

export const GET_ALL_FAQ_SUCCESS = '[FAQ] get all success';
export const GET_FAQ_BY_ID_SUCCESS = '[FAQ] get faq by id success';
export const CREATE_FAQ_SUCCESS = '[FAQ] create faq success';
export const UPDATE_FAQ_SUCCESS = '[FAQ] update faq success';
export const DELETE_FAQ_SUCCESS = '[FAQ] delete faq success';

export const GET_ALL_FAQ_ERROR = '[FAQ] get all error';
export const GET_FAQ_BY_ID_ERROR = '[FAQ] get faq by id error';
export const CREATE_FAQ_ERROR = '[FAQ] create faq error';
export const UPDATE_FAQ_ERROR = '[FAQ] update faq error';
export const DELETE_FAQ_ERROR = '[FAQ] delete faq error';


export class GetAllFaq implements Action {
  readonly type = GET_ALL_FAQ;

  constructor() {
  }
}

export class GetFaqById implements Action {
  readonly type = GET_FAQ_BY_ID;

  constructor(public payload: string) {
  }
}

export class CreateFaq implements Action {
  readonly type = CREATE_FAQ;

  constructor(public payload: Faq) {
  }
}

export class UpdateFaq implements Action {
  readonly type = UPDATE_FAQ;

  constructor(public payload: Faq) {
  }
}

export class DeleteFaq implements Action {
  readonly type = DELETE_FAQ;

  constructor(public payload: Faq) {
  }
}


export class GetAllFaqSuccess implements Action {
  readonly type = GET_ALL_FAQ_SUCCESS;

  constructor(public payload: Faq[]) {
  }
}

export class GetFaqByIdSuccess implements Action {
  readonly type = GET_FAQ_BY_ID_SUCCESS;

  constructor(public payload: string) {
  }
}

export class CreateFaqSuccess implements Action {
  readonly type = CREATE_FAQ_SUCCESS;

  constructor(public payload: Faq) {
  }
}

export class UpdateFaqSuccess implements Action {
  readonly type = UPDATE_FAQ_SUCCESS;

  constructor(public payload: Faq) {
  }
}

export class DeleteFaqSuccess implements Action {
  readonly type = DELETE_FAQ_SUCCESS;

  constructor(public payload: Faq) {
  }
}


export class GetAllFaqError implements Action {
  readonly type = GET_ALL_FAQ_ERROR;

  constructor() {
  }
}

export class GetFaqByIdError implements Action {
  readonly type = GET_FAQ_BY_ID_ERROR;

  constructor(public payload: string) {
  }
}

export class CreateFaqError implements Action {
  readonly type = CREATE_FAQ_ERROR;

  constructor(public payload: Faq) {
  }
}

export class UpdateFaqError implements Action {
  readonly type = UPDATE_FAQ_ERROR;

  constructor(public payload: Faq) {
  }
}

export class DeleteFaqError implements Action {
  readonly type = DELETE_FAQ_ERROR;

  constructor(public payload: Faq) {
  }
}


export type Action = GetAllFaq | GetFaqById | CreateFaq | UpdateFaq | DeleteFaq |
  GetAllFaqSuccess | GetFaqByIdSuccess | CreateFaqSuccess | UpdateFaqSuccess | DeleteFaqSuccess |
  GetAllFaqError | GetFaqByIdError | CreateFaqError | UpdateFaqError | DeleteFaqError;
