import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {SpecialService} from '../../services/special.service';
import {Subscription} from 'rxjs';
import * as rootStore from '../../store/reducers';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-special',
  templateUrl: './special.component.html',
  styleUrls: ['./special.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpecialComponent implements OnInit, OnDestroy {

  special: any;

  language = 'Russian';

  private subs: Subscription[] = [];

  constructor(private _special: SpecialService,
              private store: Store<rootStore.State>) {
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(rootStore.getCurrentLanguage).subscribe(
        language => {
          this.language = language;
        }
      )
    );
    this.subs.push(
      this._special.getSpecial().subscribe(
        data => {
          this.special = data;
        }, error1 => {
          console.log(error1);
        }
      )
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  getTextFromTranslation() {
    return this.special.translations.find(t => t.language === this.language).text;
  }

  getTitleFromTranslation() {
    return this.special.translations.find(t => t.language === this.language).title;
  }
}
