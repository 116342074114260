import {Faq} from '../models/faq';
import * as FaqAction from '../actions/faq';

export interface State {
  list: Faq[];
}

export const InitialState: State = {
  list: [
    {
      id: '2',
      question: 'Сколько дней будут делать мой заказ?',
      answer: 'Стандартный срок - 5 дней. За дополнительную плату мы можем сделать заказ раньше.' +
        ' За 3 дня либо за 24 часа. Опция выбора срока находится в окне загрузки проекта.',
      translations: []
    }
  ]
};

export function reducer(state = InitialState, action: FaqAction.Action) {
  let newFaqList = state.list;
  switch (action.type) {
    case FaqAction.GET_ALL_FAQ:
      return {
        ...state
      };
    case FaqAction.GET_ALL_FAQ_SUCCESS:
      newFaqList = action['payload'];
      return {
        ...state,
        list: newFaqList
      };
    case FaqAction.GET_FAQ_BY_ID:
      return {
        ...state
      };
    case FaqAction.CREATE_FAQ:
      return {
        ...state
      };
    case FaqAction.UPDATE_FAQ:
      return {
        ...state
      };
    case FaqAction.DELETE_FAQ:
      return {
        ...state
      };
    default:
      return {
        ...state
      };
  }
}

export const getFaqList = (state: State) => state.list;
