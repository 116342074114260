import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private readonly TOKEN_ERROR = 'Access denied. Invalid token';
  user: any;

  constructor(private router: Router,
              private _user: UserService) {
  }

  ngOnInit() {
    this._user.getUser().subscribe(
      user => {
        this.user = user;
      }, err => {
        this.user = null;
        if (err.status === 401 && err.error === this.TOKEN_ERROR) {
          this._user.logout();
        }
      }
    );
  }

  ngOnDestroy() {
  }

  logout() {
    this._user.logout();
    window.location.reload();
  }
}
