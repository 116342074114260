import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  showError = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private _user: UserService) { }

  ngOnInit() {
    this._user.verify(
      this.route.snapshot.queryParams.id,
      this.route.snapshot.queryParams.token
    ).subscribe(
      success => {
        this.router.navigate(['/', 'login']);
      }, err => {
        console.log(err);
        this.showError = true;
      }
    );
  }
}
