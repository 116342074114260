import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {defer, Observable, of} from 'rxjs';
import {AboutService} from '../../services/about.service';
import * as AboutAction from '../actions/about';
import {map, switchMap} from 'rxjs/operators';
import {Action} from '@ngrx/store';


@Injectable()
export class AboutEffects {

  constructor(private actions$: Actions, private _about: AboutService) {
  }

  @Effect()
  getAllAbouts$: Observable<any> = this.actions$.pipe(
    ofType<AboutAction.GetAllAbout>(
      AboutAction.GET_ALL_ABOUT
    ),
    switchMap(() => {
      return this._about.getAllAbout()
        .pipe(
          map((aboutEntityArray: any) => {
            const aboutModelArray = [];
            aboutEntityArray.forEach((aboutEntity: any) => {
              aboutModelArray.push({
                id: aboutEntity['_id'],
                text: aboutEntity['text']
              });
            });
            return new AboutAction.GetAllAboutSuccess(aboutModelArray);
          }));
    })
  );

  @Effect()
  init$: Observable<Action> = defer(() => {
    return of(new AboutAction.GetAllAbout());
  });
}
