import {
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {Store} from '@ngrx/store';
import * as rootStore from '../../store/reducers';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, OnDestroy {

  @Output('select') select = new EventEmitter();

  @ViewChildren('back') back;

  isShow = true;

  language = '';

  faqList = [];

  @Input()
  set showHeader(show: boolean) {
    this.isShow = show;
  }

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<rootStore.State>,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(rootStore.getFaqList).subscribe(data => {
        this.faqList = data;
      }));
    this.subscriptions.push(
      this.store.select(rootStore.getCurrentLanguage).subscribe(
        language => {
          this.language = language;
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  selectDiv(selectedDiv, index) {
    this.back.forEach(
      b => {
        this.renderer.setStyle(b.nativeElement, 'display', 'none');
      }
    );
    this.select.emit({
      elem: selectedDiv,
      faq: this.faqList[index]
    });
  }

  getAnswerFromTranslation(id: any) {
    return this.faqList.find(f => f.id === id).translations.find(t => t.language === this.language).answer;
  }

  getQuestionFromTranslation(id: any) {
    return this.faqList.find(f => f.id === id).translations.find(t => t.language === this.language).question;
  }
}
