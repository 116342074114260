import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Service} from '../models/service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get('/api/services');
  }

  public getBeforeImage(id: string): Observable<Blob> {
    return this.http.get(`/api/services/beforeImage/${id}`, {responseType: 'blob'});
  }

  public getAfterImage(id: string): Observable<Blob> {
    return this.http.get(`/api/services/afterImage/${id}`, {responseType: 'blob'});
  }

  public create(service: Service) {
    const request = new FormData();

    request.append('beforeImage', service.beforeImage, service.beforeImage.name);
    request.append('afterImage', service.afterImage, service.afterImage.name);
    request.append('title', service.title);
    request.append('text', service.text);
    request.append('urlFragment', service.urlFragment);

    return this.http.post('/api/services', request);
  }

  public update(service: Service) {
    return this.http.put(`/api/services`, service);
  }

  public updateBeforeImage(id, image) {
    const request = new FormData();

    request.append('id', id);
    request.append('image', image);

    return this.http.put(`/api/services/beforeImage`, request);
  }

  public updateAfterImage(id, image) {
    const request = new FormData();

    request.append('id', id);
    request.append('image', image);

    return this.http.put(`/api/services/afterImage`, request);
  }

  public addTranslation(language: string, text: string, title: string, id: string) {
    return this.http.put(`/api/services/translation/${id}`, {
      language: language,
      text: text,
      title: title
    });
  }

  public delete(id: string) {
    return this.http.delete(`/api/services/${id}`);
  }
}
