import {Action} from '@ngrx/store';

export const GET_LANGUAGE = '[LANGUAGE] get';
export const SET_LANGUAGE = '[LANGUAGE] set';

export const language = 'language';
export const russian = 'Russian';
export const english = 'English';

export const GET_ALL_LANGUAGES = '[LANGUAGE] get all';
export const GET_ALL_LANGUAGES_ERROR = '[LANGUAGE] get all error';
export const GET_ALL_LANGUAGES_SUCCESS = '[LANGUAGE] get all success';


export class GetLanguage implements Action {
  readonly type = GET_LANGUAGE;

  constructor() {
  }
}

export class SetLanguage implements Action {
  readonly type = SET_LANGUAGE;

  constructor(public payload: string) {
  }
}

export class GetAllLanguages implements Action {
  readonly type = GET_ALL_LANGUAGES;

  constructor() {
  }
}

export class GetAllLanguagesSuccess implements Action {
  readonly type = GET_ALL_LANGUAGES_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class GetAllLanguagesError implements Action {
  readonly type = GET_ALL_LANGUAGES_ERROR;

  constructor() {
  }
}


export type Action = GetLanguage | SetLanguage | GetAllLanguages | GetAllLanguagesSuccess | GetAllLanguagesError;
