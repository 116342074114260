import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  sent = false;
  error = false;

  emailForm: FormGroup;

  private subs: Subscription[] = [];

  constructor(private fb: FormBuilder,
              private renderer: Renderer2,
              private _user: UserService) {
    this.emailForm = fb.group({
      email: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  next() {
    if (this.emailForm.controls['email'].valid) {
      this.subs.push(
        this._user.checkEmail(this.emailForm.controls['email'].value).subscribe(
          (status: any) => {
            if (status.exist === true) {
              this.subs.push(
                this._user.sendResetMail(this.emailForm.controls['email'].value, status.id).subscribe(
                  success => {
                    this.sent = true;
                  }, err => {
                    console.log(err);
                  }
                )
              );
            } else {
              this.emailForm.reset();
              this.error = true;
            }
          }, err => {
            console.log(err);
          }
        )
      );
    }
  }
}
