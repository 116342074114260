import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RegisterComponent} from './components/register/register.component';
import {ServicesComponent} from './components/services/services.component';
import {LoginComponent} from './components/login/login.component';
import {FaqComponent} from './shared/faq/faq.component';
import {AboutComponent} from './shared/about/about.component';
import {ContactComponent} from './components/contact/contact.component';
import {PriceComponent} from './components/price/price.component';
import {SpecialComponent} from './components/special/special.component';
import {DownloadComponent} from './components/download/download.component';
import {MorePhotosComponent} from './components/more-photos/more-photos.component';
import {PhotoSliderComponent} from './components/photo-slider/photo-slider.component';
import {AuthGuardGuard} from './guards/auth-guard.guard';
import {MainComponent} from './components/main/main.component';
import {CommentComponent} from './components/comment/comment.component';
import {VerifyComponent} from './components/verify/verify.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {DownloadTextComponent} from './components/download-text/download-text.component';
import {ConfirmComponent} from './components/confirm/confirm.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {ResetConfirmComponent} from './components/reset-confirm/reset-confirm.component';

const routes: Routes = [
  {
    path: 'more-photos',
    component: MorePhotosComponent
  },
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'download',
    component: DownloadComponent
  },
  {
    path: 'download-extended',
    component: DownloadTextComponent
  },
  {
    path: 'download-confirm',
    component: ConfirmComponent
  },
  {
    path: 'password-reset',
    component: ResetPasswordComponent
  },
  {
    path: 'reset',
    component: ResetConfirmComponent
  },
  {
    path: 'prices',
    component: PriceComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'special',
    component: SpecialComponent
  },
  {
    path: 'test',
    component: PhotoSliderComponent,
  },
  {
    path: 'comment',
    component: CommentComponent
  },
  {
    path: 'verify',
    component: VerifyComponent
  },
  {
    path: 'user',
    loadChildren: './user/user.module#UserModule',
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'notFound',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/notFound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
