import {Injectable} from '@angular/core';
import {TranslationService} from './translation.service';
import {Store} from '@ngrx/store';
import * as rootStore from '../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class TranslatingService {

  private translations = [];
  private language = 'Russian';

  constructor(private _translation: TranslationService,
              private store: Store<rootStore.State>) {
    this._translation.getAll().subscribe(
      (translations: any) => {
        this.translations = translations;
      }
    );
    this.store.select(rootStore.getCurrentLanguage).subscribe(
      language => {
        this.language = language;
      }
    );
  }

  public translate(key: string): string {
    if (this.translations.length === 0) {
      return key;
    } else {
      const translated = this.translations.find(t => t.key === key && t.language === this.language);
      if (translated) {
        return translated.value ? translated.value : key;
      } else {
        return key;
      }
    }
  }
}
