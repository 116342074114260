import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-photo-slider',
  templateUrl: './photo-slider.component.html',
  styleUrls: ['./photo-slider.component.scss']
})
export class PhotoSliderComponent implements OnInit {

  sliderControl = new FormControl(50);

  @Input('hide') hide = false;
  @Input('before') before = '../../../assets/images/before.png';
  @Input('after') after = '../../../assets/images/after.png';

  @ViewChild('frame') frame: ElementRef;
  @ViewChild('image') image: ElementRef;
  @ViewChild('thumb') thumb: ElementRef;

  constructor(private renderer: Renderer2) {

  }

  ngOnInit() {
    if (this.hide === true) {
      this.renderer.addClass(this.frame.nativeElement, 'pht_hide');
      this.renderer.removeClass(this.image.nativeElement, 'before');
    }
    this.sliderControl.valueChanges.subscribe(
      (value: number) => {
        const newValue = (value - 50) * 2.4;
        this.renderer.setStyle(this.frame.nativeElement, 'transform', `translateX(${newValue}px)`);
        this.renderer.setStyle(this.thumb.nativeElement, 'transform', `translateX(${newValue}px)`);
        this.renderer.setStyle(this.image.nativeElement, 'transform', `translateX(${-(newValue)}px)`);
      });
  }
}
