import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {state, style, trigger} from '@angular/animations';
import {interval, Subscription} from 'rxjs';
import * as rootStore from '../../store/reducers';
import {russian} from '../../store/actions/language';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild('container') container: ElementRef;

  imageUrlArray = [
    '../../../assets/images/main1.png',
    '../../../assets/images/main3.jpg',
    '../../../assets/images/main1.jpg',
    '../../../assets/images/main4.png',
    '../../../assets/images/main2.png',
    '../../../assets/images/main3.png'
  ];

  variableHeight = '525px';

  showRussian = true;

  private subs: Subscription[] = [];

  constructor(private store: Store<rootStore.State>) {
    window.addEventListener('resize', (v) => {
      this.variableHeight = `${this.container.nativeElement.offsetWidth * 0.5625}px`;
    });
  }

  ngOnInit() {
    this.variableHeight = `${(this.container.nativeElement.offsetWidth * 0.5625) * 0.85}px`;
    this.subs.push(
      this.store.select(rootStore.getCurrentLanguage).subscribe(
        (lang: any) => {
          this.showRussian = lang === russian;
          this.imageUrlArray[4] = this.showRussian ? '../../../assets/images/main2.png' : '../../../assets/images/main2_eng.png';
          this.imageUrlArray[5] = this.showRussian ? '../../../assets/images/main3.png' : '../../../assets/images/main3_eng.png';
        }
      )
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
