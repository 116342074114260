import {
  AfterContentChecked,
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit, AfterViewChecked, OnDestroy {

  private MB = 1048576;

  @Input('multiple') multiple = false;
  @Input('maxSize') maxSize = 15;
  @Input('reset') reset: EventEmitter<any>;

  @Input('setUrl')
  set setUrl(setUrl) {
    this.imageUrl = setUrl;
  }

  @Output('imageSelected') imageSelected = new EventEmitter();

  @ViewChild('imageInput') imageInput: ElementRef;

  beforeSizeError = false;
  imageUrl = '';

  urls = [];

  private subscription: Subscription;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    if (this.reset) {
      this.subscription = this.reset.subscribe(
        reset => {
          if (reset === true && this.imageInput) {
            if (this.multiple) {
              this.urls = [];
              this.imageInput.nativeElement.value = '';
              if (this.imageInput.nativeElement.files.length > 0) {
                this.imageInput.nativeElement.files = [];
              }
            } else {
              this.imageUrl = '';
              this.imageInput.nativeElement.value = '';
              if (this.imageInput.nativeElement.files.length > 0) {
                this.imageInput.nativeElement.files = [];
              }
            }
          }
        }
      );
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      if (event.target.files[0].size > (this.MB * this.maxSize)) {
        this.beforeSizeError = true;
        this.imageSelected.emit(null);
      } else {
        this.beforeSizeError = false;
        this.imageSelected.emit(event.target.files[0]);
      }
    }
  }

  onFilesSelect(event) {
    if (event.target.files && event.target.files[0]) {
      this.urls = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (e: any) => {
          if (event.target.files[i].size > (this.MB * this.maxSize)) {
            this.beforeSizeError = true;
            this.imageSelected.emit(null);
          } else {
            this.beforeSizeError = false;
            this.imageSelected.emit({
              imageBlob: event.target.files[i],
              imageUrl: e.target.result
            });
          }
          this.urls.push(e.target.result);
        };
      }
    }
  }
}
