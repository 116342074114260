import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  error = false;

  private subscription: Subscription;

  constructor(private fb: FormBuilder,
              private _user: UserService,
              private router: Router) {
    this.loginForm = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (this._user.isLoged()) {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async fbLogin() {
    const result = await this._user.fbLogin();
    if (result) {
      this.router.navigate(['/user/profile'], {fragment: 'info'});
    } else {
      this.error = true;
    }
  }

  doLogin() {
    this.subscription = this._user.login(
      this.loginForm.controls['email'].value,
      this.loginForm.controls['password'].value).subscribe(
      (data: any) => {
        this._user.setToken(data.token);
        this.router.navigate(['/user/profile'], {fragment: 'info'});
      }, err => {
        this.loginForm.controls['password'].setValue('');
        this.error = true;
      }
    );
  }
}
