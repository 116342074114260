import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {language} from '../store/actions/language';

export const AUTH_TOKEN = 'pht_auth_token';

declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private jwt;

  constructor(private http: HttpClient) {
    this.jwt = new JwtHelperService();
    FB.init({
      appId: '506688106718017',
      status: false, // the SDK will attempt to get info about the current user immediately after init
      cookie: false,  // enable cookies to allow the server to access
      // the session
      xfbml: false,  // With xfbml set to true, the SDK will parse your page's DOM to find and initialize any
      // social plugins that have been added using XFBML
      version: 'v2.8' // use graph api version 2.5
    });
  }

  public register(user: any) {
    const requestBody = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      password: user.password,
      country: user.country,
      genre: user.genre,
      language: localStorage.getItem(language)
    };
    return this.http.post('/api/auth/register', requestBody);
  }

  public updateUser(user: any) {
    return this.http.put('/api/users', {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      country: user.country,
      genre: user.genre,
    });
  }

  public fbLogin() {
    const getRegisterData = (r1, r2) => {
      const [firstName, lastName] = r2.name.split(' ');
      return {
        accessToken: r1.authResponse.accessToken,
        first_name: firstName,
        last_name: lastName,
        email: typeof r2.email === 'undefined' ? `${r2.id}@facebook.com` : r2.email,
        password: r2.id,
        phone: '',
        genre: 'photo',
        country: 'Unset'
      };
    };

    const processRegister = (registerResult: any, r: any, resolve, reject) => {
      if (registerResult.result === 'User already exists' || registerResult.result === 'created') {
        this.login(r.email, r.password).subscribe(
          (loginResult: any) => {
            this.setToken(loginResult.token);
            resolve(true);
          },
          () => {
            FB.logout(function(response) {});
            reject(false);
          }
        );
      } else {
        reject(false);
      }
    };

    return new Promise((resolve, reject) => {
      FB.login(
        (result: any) => {
          if (result.authResponse) {
            FB.api('/me?fields=id,name,email', (r) => {
              const userData = getRegisterData(result, r);

              this.http.post('/api/auth/facebook', userData).subscribe(
                (registerResult) => processRegister(registerResult, userData, resolve, reject)
              );
            });
          } else {
            reject(false);
          }
        }, {scope: 'public_profile,email'});
    });
  }

  public login(email: string, password: string) {
    return this.http.post('/api/auth/login', {email: email, password: password});
  }

  public logout() {
    localStorage.removeItem(AUTH_TOKEN);
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    try {
      FB.logout(function (response) {});
    } catch (e) {
    }
  }

  public getID() {
    return this.jwt.decodeToken(this.getToken())._id;
  }

  public isLoged() {
    if (localStorage.getItem(AUTH_TOKEN) && !this.jwt.isTokenExpired(localStorage.getItem(AUTH_TOKEN))) {
      return true;
    } else {
      return false;
    }
  }

  public checkEmail(email: string) {
    return this.http.post('/api/users/check', {
      email: email
    });
  }

  public sendResetMail(email: string, id: string) {
    return this.http.post('/api/users/setResetMail', {
      email: email,
      id: id,
      language: localStorage.getItem(language)
    });
  }

  public getUser() {
    return this.http.get('/api/users/details');
  }

  public verify(id, token) {
    return this.http.get(`/api/auth/verify?id=${id}&token=${token}`);
  }

  public reset(id, token, password) {
    return this.http.post(`/api/auth/reset`, {
      id: id,
      resetToken: token,
      password: password
    });
  }

  public getToken(): string {
    return localStorage.getItem(AUTH_TOKEN);
  }

  public setToken(token: string) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
}
