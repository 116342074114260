import * as LanguageAction from '../actions/language';
import {language} from '../actions/language';


export interface State {
  currentLanguage: string;
  languages: any[];
}

export const InitialState: State = {
  currentLanguage: 'English',
  languages: []
};

export function reducer(state = InitialState, action: LanguageAction.Action) {
  switch (action.type) {
    case LanguageAction.GET_ALL_LANGUAGES:
      return {
        ...state
      };
    case LanguageAction.GET_ALL_LANGUAGES_SUCCESS:
      let newLanguagesList = state.languages;
      newLanguagesList = action['payload'];
      return {
        ...state,
        languages: newLanguagesList
      };
    case LanguageAction.SET_LANGUAGE:
      let newLanguage = state.currentLanguage;
      newLanguage = action['payload'];
      localStorage.setItem(language, newLanguage);
      return {
        ...state,
        currentLanguage: newLanguage
      };
    default:
      return {
        ...state
      };
  }
}

export const getLanguage = (state: State) => state.currentLanguage;
export const getAllLanguages = (state: State) => state.languages;
