import {Component, OnDestroy, OnInit} from '@angular/core';
import {AboutService} from '../../services/about.service';
import {Subscription} from 'rxjs';
import {TranslatingService} from '../../services/translating.service';
import * as rootStore from '../../store/reducers';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  aboutList = [];

  language = 'Russian';

  private subscriptions: Subscription[] = [];

  constructor(private _about: AboutService,
              private _t: TranslatingService,
              private store: Store<rootStore.State>) {
    this.store.select(rootStore.getCurrentLanguage).subscribe(
      language => {
        this.language = language;
      }
    );
  }

  ngOnInit() {
    this.loadAboutList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTextFromTranslation(id: string) {
    return this.aboutList.find(a => a._id === id).translations.find(t => t.language === this.language).text;
  }

  private loadAboutList() {
    this.subscriptions.push(
      this._about.getAllAbout().subscribe(
        (data: any) => {
          if (data) {
            this.aboutList = data;
          }
        },
        error1 => {
          console.log(error1);
        }
      ));
  }
}

