import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {PricesService} from '../../services/prices.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderService} from '../../services/order.service';
import {concatMap, map} from 'rxjs/operators';
import {from, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';
import {TranslatingService} from '../../services/translating.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, OnDestroy {

  @ViewChild('curtain') curtain;
  @ViewChild('root') root;

  @ViewChild('downloadModal') downloadModal: ElementRef;
  @ViewChild('addModal') addModal: ElementRef;

  itemID = 0;
  downloaded = 0;

  downloadComplete = false;

  logined = false;
  pricesList = [];

  addedList = [];
  orderID = '';

  imageReset = new EventEmitter<any>();
  images: File[] = [];

  addForm: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(private _user: UserService,
              private fb: FormBuilder,
              private _order: OrderService,
              private _price: PricesService,
              private router: Router,
              private toaster: ToastrService,
              private translation: TranslatingService,
              private titleService: Title,
              private renderer: Renderer2) {
    this.logined = this._user.isLoged();
    this.addForm = fb.group({
      hurry: [false, Validators.required],
      price: ['', Validators.required],
      description: ['']
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Загрузка проекта');
    this.subscriptions.push(
      this._price.getAll().subscribe(
        (prices: any[]) => {
          this.pricesList = prices;
          if (this.pricesList.length > 0) {
            this.addForm.controls.price.setValue(this.pricesList[0]._id);
          }
        }, err => {
          console.log(err);
        }
      ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onImageSelected(selectedImage) {
    this.images.push(selectedImage);
  }

  refresh() {
    this.addForm.reset();
    if (this.images.length !== 0) {
      this.images = [];
      this.imageReset.emit(true);
    }
  }

  removeItem(id) {
    const newAddedList = [];
    this.addedList.forEach(addedItem => {
      if (addedItem.id !== id) {
        newAddedList.push(addedItem);
      }
    });
    this.addedList = newAddedList;
  }

  addItem() {
    if (this.addForm.valid && this.images.length !== 0) {
      const isHurry = this.addForm.controls['hurry'].value;
      const selectedPriceID = this.addForm.controls['price'].value;
      const description = this.addForm.controls['description'].value;

      this.images.forEach(
        (tempImage: any) => {
          if (this.addedList.find(a => a.imageUrl === tempImage.imageUrl &&
            a.price_id === this.pricesList.find(p => p._id === this.addForm.controls['price'].value)._id)) {
            this.toaster.warning(this.translation.translate('download.toast.repeat'));
          } else {
            this.addedList.push({
              id: this.itemID++,
              title: this.pricesList.find(p => p._id === this.addForm.controls['price'].value).title,
              price: this.pricesList.find(p => p._id === this.addForm.controls['price'].value).price,
              price_id: this.pricesList.find(p => p._id === this.addForm.controls['price'].value)._id,
              imageUrl: tempImage.imageUrl,
              image: tempImage.imageBlob
            });
          }
        }
      );
      this.refresh();
      this.addForm.controls['price'].setValue(selectedPriceID);
      this.addForm.controls['hurry'].setValue(isHurry);
      this.addForm.controls['description'].setValue(description);
    }
  }

  // CONFIRM MODAL

  openAddModal() {
    this.renderer.setStyle(this.addModal.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'block');
    this.renderer.addClass(this.getBody(), 'modal-open');
  }

  closeAddModal(save?: boolean) {
    this.renderer.setStyle(this.addModal.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'none');
    this.renderer.removeClass(this.getBody(), 'modal-open');

    if (save && save === true && this.addedList.length > 0) {
      this.subscriptions.push(this.createOrder());
    }
  }

  // DOWNLOAD MODAL

  openDownloadModal() {
    this.renderer.setStyle(this.downloadModal.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'block');
    this.renderer.addClass(this.getBody(), 'modal-open');
  }

  closeDownloadModal() {
    this.itemID = 0;
    this.downloaded = 0;
    this.downloadComplete = false;
    this.addedList = [];
    this.renderer.setStyle(this.downloadModal.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'none');
    this.renderer.removeClass(this.getBody(), 'modal-open');
  }

  closeAfterDownload() {
    this.closeDownloadModal();
    this._order.notifyAboutNewOrder(this.orderID).subscribe(
      success => {
      }, error1 => {
        console.log(error1);
      }
    );
    this.orderID = '';
    this.router.navigate(['', 'download-confirm']);
  }

  private createOrder() {
    return this._order.createOrder(
      this._user.getID(),
      this.addForm.controls['hurry'].value,
      this.addForm.controls['description'].value).subscribe(
      success => {
        this.orderID = success['_id'];

        // ALL TOGETHER

        // this.subscriptions.push(
        //   from(this.addedList).pipe(map(
        //     item => {
        //       return this._order.createOrderItem({
        //         image: item.image,
        //         order: this.orderID,
        //         price: item.price_id
        //       });
        //     }
        //   )).subscribe(
        //     mapNext => {
        //       this.subscriptions.push(
        //         mapNext.subscribe(
        //           dowload_success => {
        //             this.downloaded += 1;
        //             if (this.downloaded === this.addedList.length) {
        //               this.downloadComplete = true;
        //             }
        //           }, download_error => {
        //             console.log(download_error);
        //           }
        //         )
        //       );
        //     }, mapError => {
        //       console.log(mapError);
        //     }
        //   ));

        // ONE BY ONE

        this.subscriptions.push(
          from(this.addedList).pipe(
            concatMap(item => {
              return this._order.createOrderItem({
                image: item.image,
                order: this.orderID,
                price: item.price_id
              });
            })
          ).subscribe(
            orderItem => {
              this.downloaded += 1;
            }, err => {
              console.log(err);
            }, () => {
              this.downloadComplete = true;
            }
          )
        );
        this.openDownloadModal();
      }, err => {
        this.toaster.error(this.translation.translate('download.toast.error'));
        this.closeDownloadModal();
        console.log(err);
      }
    );
  }

  private getBody() {
    let body = this.root.nativeElement.parentElement;
    while (body.parentElement !== null && body.parentElement.parentElement !== null) {
      body = body.parentElement;
    }
    return body;
  }
}
