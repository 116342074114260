import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {language} from '../../store/actions/language';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  confirmForm: FormGroup;
  language = 'Russian';

  constructor(private fb: FormBuilder) {
    this.confirmForm = fb.group({
      confirm: [false, Validators.required]
    });
  }

  ngOnInit() {
    this.language = localStorage.getItem(language);
  }
}
