import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Special} from '../models/special';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecialService {

  constructor(private http: HttpClient) {
  }

  public getSpecial() {
    return this.http.get('/api/special');
  }

  public updateSpecial(special: Special) {
    return this.http.post('/api/special', special);
  }

  public addTranslation(language: string, text: string, title: string) {
    return this.http.put(`/api/special/translation`, {
      language: language,
      text: text,
      title: title
    });
  }
}
