import * as AboutAction from '../actions/about';
import {About} from '../models/about';

export interface State {
  list: About[];
}

export const InitialState: State = {
  list: [
    {
      id: '5c0839083574d0196f22187d',
      text: 'Мы-команда профессионалов, которая справится со всеми творческими замыслами наших клиентов на высшем уровне!'
    },
    {
      id: '5c083989f97ff31990e53e85',
      text: 'Выполним задание в кратчайшие сроки'
    }
  ]
};

export function reducer(state = InitialState, action: AboutAction.Action) {
  switch (action.type) {
    case AboutAction.GET_ALL_ABOUT:
      return {
        ...state
      };
    case AboutAction.GET_ALL_ABOUT_SUCCESS:
      let newAboutList = state.list;
      newAboutList = action['payload'];
      return {
        ...state,
        list: newAboutList
      };
    case AboutAction.CREATE_ABOUT:
      const newList = state.list;
      if (action instanceof AboutAction.CreateAbout) {
        newList.push(action.payload);
      }
      return {
        ...state,
        list: newList
      };
    default:
      return {
        ...state
      };
  }
}

export const getAboutList = (state: State) => state.list;
