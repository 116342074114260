import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get('/api/price');
  }

  public getByID(id: string) {
    return this.http.get(`/api/price/${id}`);
  }

  public getImage(id: string) {
    return this.http.get(`/api/price/image/${id}`, {responseType: 'blob'});
  }

  public create(price) {
    return this.http.post('/api/price', price);
  }

  public createSmallImage(id: string, image) {
    const request = new FormData();

    request.append('id', id);
    request.append('image', image);

    return this.http.post('/api/price/small', request);
  }

  public createFullImage(id: string, image) {
    const request = new FormData();

    request.append('id', id);
    request.append('image', image);

    return this.http.post('/api/price/full', request);
  }

  public update(price) {
    return this.http.put(`/api/price`, price);
  }

  public updateSmallImage(id: string, image) {
    const request = new FormData();

    request.append('id', id);
    request.append('image', image);

    return this.http.put(`/api/price/small`, request);
  }

  public updateFullImage(id: string, image) {
    const request = new FormData();

    request.append('id', id);
    request.append('image', image);

    return this.http.put(`/api/price/full`, request);
  }

  public addTranslation(language: string, text: string, title: string, id: string) {
    return this.http.put(`/api/price/translation/${id}`, {
      language: language,
      text: text,
      title: title
    });
  }

  public delete(id) {
    return this.http.delete(`/api/price/${id}`);
  }
}
