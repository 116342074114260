import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private http: HttpClient) {
  }

  public getAll() {
    return this.http.get('/api/language/all');
  }

  public create(language: any) {
    return this.http.post('/api/language', {
      name: language
    });
  }

  public update(id: string, name: string) {
    return this.http.put(`/api/language/${id}`, {name: name});
  }

  public delete(id: string) {
    return this.http.delete(`/api/language/${id}`);
  }
}
