import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ObservableInput, Subscription} from 'rxjs';
import {ExampleService} from '../../services/example.service';
import {PricesService} from '../../services/prices.service';

@Component({
  selector: 'app-more-photos',
  templateUrl: './more-photos.component.html',
  styleUrls: ['./more-photos.component.scss']
})
export class MorePhotosComponent implements OnInit, OnDestroy {

  openedPhoto = '';

  @ViewChild('photoModal') photoModal;
  @ViewChild('curtain') curtain;

  @ViewChild('lightBoxModal') lightBoxModal: ElementRef;

  selectedPrice = '';

  prices = [];
  examples = {};

  private subs: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private renderer: Renderer2,
              private router: Router,
              private _examples: ExampleService,
              private _prices: PricesService) {
  }

  ngOnInit() {
    this.subs.push(
      this._prices.getAll().subscribe(
        (prices: any) => {
          prices.forEach(price => {
            this.examples[price.urlFragment] = [];
            if (this.selectedPrice === '') {
              this.selectedPrice = price.urlFragment;
            }
          });
          this.prices = prices;
          this.subs.push(
            this._examples.getAll().subscribe(
              (examples: any) => {
                this.subs.push(
                  this.activatedRoute.fragment.subscribe(
                    fragment => {
                      this.changePrice(fragment);
                    }
                  )
                );
                examples.forEach(example => {
                  if (this.examples[example.priceUrl]) {
                    this.examples[example.priceUrl].push(example);
                  }
                });
              }, err => {
                console.log(err);
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  changePrice(priceUrl: string) {
    this.selectedPrice = priceUrl;
  }

  openLightBoxModal(id) {
    this.openedPhoto = id;

    this.renderer.setStyle(this.lightBoxModal.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'block');
    this.renderer.addClass(this.getBody(), 'modal-open');
  }

  closeLightBoxModal() {
    this.openedPhoto = null;
    this.renderer.setStyle(this.lightBoxModal.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'none');
    this.renderer.removeClass(this.getBody(), 'modal-open');
  }

  // prevImage() {
  //   console.log(this.openedPhoto);
  //   for (let i = 0; i < this.examples[this.selectedPrice].length; i++) {
  //     if (this.examples[this.selectedPrice][i]._id === this.openedPhoto) {
  //       if (i - 1 > 0) {
  //         this.openedPhoto = this.examples[this.selectedPrice][i - 1]._id;
  //       } else {
  //         this.openedPhoto = this.examples[this.selectedPrice][this.examples[this.selectedPrice].length - 1]._id;
  //       }
  //       break;
  //     }
  //   }
  //   console.log(this.openedPhoto);
  // }
  //
  // nextImage() {
  //   console.log(this.openedPhoto);
  //   for (let i = 0; i < this.examples[this.selectedPrice].length; i++) {
  //     if (this.examples[this.selectedPrice][i].id === this.openedPhoto) {
  //       if (i + 1 < this.examples[this.selectedPrice].length) {
  //         this.openedPhoto = this.examples[this.selectedPrice][i + 1]._id;
  //       } else {
  //         this.openedPhoto = this.examples[this.selectedPrice][0]._id;
  //       }
  //       break;
  //     }
  //   }
  //   console.log(this.openedPhoto);
  // }

  openModal(photo) {
    this.openedPhoto = photo;
    this.renderer.setStyle(this.photoModal.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'block');
    this.renderer.addClass(this.getBody(), 'modal-open');
  }

  closeModal() {
    this.renderer.setStyle(this.photoModal.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.curtain.nativeElement, 'display', 'none');
    this.renderer.removeClass(this.getBody(), 'modal-open');
  }

  private getBody() {
    let body = this.curtain.nativeElement.parentElement;
    while (body.parentElement !== null && body.parentElement.parentElement !== null) {
      body = body.parentElement;
    }
    return body;
  }
}
