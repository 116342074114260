import * as LanguageReducer from './languageReducer';
import * as AboutReducer from './aboutReducer';
import * as FaqReducer from './faqReducer';
import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';


export interface State {
  language: LanguageReducer.State;
  about: AboutReducer.State;
  faq: FaqReducer.State;
}

export const reducers: ActionReducerMap<State> = {
  language: LanguageReducer.reducer,
  about: AboutReducer.reducer,
  faq: FaqReducer.reducer
};


export const getAboutListState = createFeatureSelector<AboutReducer.State>('about');
export const getAboutsList = createSelector(getAboutListState, AboutReducer.getAboutList);

export const getFaqListState = createFeatureSelector<FaqReducer.State>('faq');
export const getFaqList = createSelector(getFaqListState, FaqReducer.getFaqList);

export const getLanguageState = createFeatureSelector<LanguageReducer.State>('language');
export const getCurrentLanguage = createSelector(getLanguageState, LanguageReducer.getLanguage);
export const getLanguageList = createSelector(getLanguageState, LanguageReducer.getAllLanguages);
