import {Pipe, PipeTransform} from '@angular/core';
import {TranslatingService} from '../../services/translating.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  private dictionary = {
    key: 'value',
    about: {
      text: {
        title: 'международный сервис ретуши фотографий',
        label: 'PHOTOLAB'
      },
      button: {
        download: 'Загрузить проект'
      }
    },
    header: {
      button: {
        enter: '@header.button.enter',
        leave: '@header.button.leave',
        profile: '@header.button.profile',
        register: '@header.button.register',
        panel: '@header.button.panel'
      },
      link: {}
    }
  };

  constructor(private _translating: TranslatingService) {
  }

  transform(value: string, defaultValue?: any): string {
    return this._translating.translate(value);
  }

  // transform(value: string, defaultValue: any): string {
  //   const key_array = value.toString().split('.');
  //   let temp_dictionary = this.dictionary;
  //
  //   key_array.forEach(key => {
  //     if (temp_dictionary[key]) {
  //       temp_dictionary = temp_dictionary[key];
  //     }
  //   });
  //   return temp_dictionary !== undefined && !(temp_dictionary instanceof Object)
  //     ? temp_dictionary
  //     : value;
  // }
}
