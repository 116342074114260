import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommentService} from '../../services/comment.service';
import {Comment} from '../../models/comment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnDestroy {

  commentForm: FormGroup;
  commentsList: Comment[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private _comment: CommentService, private fb: FormBuilder) {
    this.commentForm = fb.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      text: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this._comment.getComments(true).subscribe(
        (comments: Comment[]) => {
          comments.forEach(
            (commentEntity) => {
              this.commentsList.push(
                {
                  first_name: commentEntity.first_name,
                  last_name: commentEntity.last_name,
                  text: commentEntity.text,
                  date: commentEntity.date
                }
              );
            }
          );
        }, err => {
          console.log(err);
        }
      ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  saveComment() {
    if (this.commentForm.valid) {
      this.subscriptions.push(
        this._comment.createComment(
          {
            first_name: this.commentForm.controls['first_name'].value,
            last_name: this.commentForm.controls['last_name'].value,
            text: this.commentForm.controls['text'].value,
          }
        ).subscribe(
          success => {
            this.commentForm.reset();
          }, error1 => {
            console.log(error1);
          }
        ));
    }
  }
}
