import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Comment} from '../models/comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient) {
  }

  public getComments(checked = false) {
    return this.http.get(`/api/comment/${checked}`);
  }

  public getCommentByID(id: string) {
    return this.http.get(`/api/comment/one/${id}`);
  }

  public checkComment(id: string) {
    return this.http.get(`/api/comment/check/${id}`);
  }

  public createComment(comment: Comment) {
    return this.http.post(`/api/comment`, comment);
  }

  public updateComment(comment: Comment, id) {
    return this.http.put(`/api/comment/${id}`, comment);
  }

  public deleteComment(id) {
    return this.http.delete(`/api/comment/${id}`);
  }
}
