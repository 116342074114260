import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {RouterModule} from '@angular/router';
import {TabsComponent} from './tabs/tabs.component';
import {TabHeaderComponent} from './tabs/tab-header/tab-header.component';
import {TabContentComponent} from './tabs/tab-content/tab-content.component';
import {HttpClientModule} from '@angular/common/http';
import {AboutComponent} from './about/about.component';
import {FaqComponent} from './faq/faq.component';
import { ImageLoaderComponent } from './image-loader/image-loader.component';
import {TranslatePipe} from './pipes/translate.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { OrderInfoComponent } from './order-info/order-info.component';
import { StatusPipe } from './pipes/status.pipe';
import { PayedPipe } from './pipes/payed.pipe';
import { FooterComponent } from './footer/footer.component';
import { ChangeLanguageComponent } from './change-language/change-language.component';
import {ReactiveFormsModule} from '@angular/forms';
import { TopSliderComponent } from './top-slider/top-slider.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  exports: [
    AboutComponent,
    FaqComponent,
    HeaderComponent,
    TabsComponent,
    TabHeaderComponent,
    TabContentComponent,
    ImageLoaderComponent,
    TranslatePipe,
    SafeHtmlPipe,
    OrderInfoComponent,
    StatusPipe,
    PayedPipe,
    FooterComponent,
    ChangeLanguageComponent,
    TopSliderComponent
  ],
  declarations: [
    AboutComponent,
    FaqComponent,
    HeaderComponent,
    TabsComponent,
    TabHeaderComponent,
    TabContentComponent,
    ImageLoaderComponent,
    TranslatePipe,
    SafeHtmlPipe,
    OrderInfoComponent,
    StatusPipe,
    PayedPipe,
    FooterComponent,
    ChangeLanguageComponent,
    TopSliderComponent
  ]
})
export class SharedModule {
  // TODO: Tabs unsubscribe
}
