import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {FaqService} from '../../services/faq.service';
import {defer, Observable, of} from 'rxjs';
import * as FaqAction from '../actions/faq';
import {map, switchMap} from 'rxjs/operators';
import {Action} from '@ngrx/store';

@Injectable()
export class FaqEffects {

  constructor(private actions$: Actions, private _faq: FaqService) {
  }

  @Effect()
  getAllFaqs$: Observable<any> = this.actions$.pipe(
    ofType<FaqAction.GetAllFaq>(
      FaqAction.GET_ALL_FAQ
    ),
    switchMap(() => {
      return this._faq.getAllFaq()
        .pipe(
          map((faqEntityArray: any) => {
            const faqModelArray = [];
            faqEntityArray.forEach((faqEntity: any) => {
              faqModelArray.push({
                id: faqEntity['_id'],
                question: faqEntity['question'],
                answer: faqEntity['answer'],
                translations: faqEntity['translations']
              });
            });
            return new FaqAction.GetAllFaqSuccess(faqModelArray);
          }));
    })
  );

  @Effect()
  init$: Observable<Action> = defer(() => {
    return of(new FaqAction.GetAllFaq());
  });
}
