import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef, Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild
} from '@angular/core';
import {TabHeaderComponent} from './tab-header/tab-header.component';
import {TabContentComponent} from './tab-content/tab-content.component';
import {AppTabSelectedEvent} from '../../models/app.tab.selected.event';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, AfterContentInit {

  @Input('tabFragment') tabFragment: Observable<string>;

  @ContentChildren(TabHeaderComponent) tabHeaders: QueryList<TabHeaderComponent>;
  @ContentChildren(TabContentComponent) tabContents: QueryList<TabContentComponent>;

  @ViewChild('headerRoot') headerRoot: ElementRef;
  @ViewChild('contentRoot') contentRoot: ElementRef;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.tabHeaders.map((tabHeader) => tabHeader.select.subscribe(
      (eventItem: AppTabSelectedEvent) => this.onTabSelected(eventItem)));
    this.clearContent();
    if (this.tabFragment) {
      this.tabFragment.subscribe((activeFragment: string) => {
        this.changeTabByFragment(activeFragment);
      });
    }
  }

  onTabSelected(event: AppTabSelectedEvent) {
    this.switchTab(event.tabEl);
    this.clearContent();
    this.contentRoot.nativeElement.childNodes.forEach((tabContent) => {
      if (tabContent.id === event.tabContentId) {
        this.renderer.setStyle(tabContent, 'display', 'block');
      }
    });
  }

  private switchTab(tabEl: ElementRef) {
    this.headerRoot.nativeElement.childNodes.forEach((tabHeaderElement) => {
      this.renderer.removeClass(tabHeaderElement.firstChild, 'active');
    });
    this.renderer.addClass(tabEl, 'active');
  }

  private changeTabByFragment(activeFragment: string) {
    let tabHeaderIndex = -1;
    const matchingHeader = this.tabHeaders.find((tabHeader, index) => {
      tabHeaderIndex = index;
      return tabHeader.tabContentId === activeFragment;
    });
    this.onTabSelected({
      tabEl: this.headerRoot.nativeElement.childNodes[tabHeaderIndex].firstChild,
      tabContentId: matchingHeader.tabContentId
    });
  }

  private clearContent() {
    this.contentRoot.nativeElement.childNodes.forEach((contentElement) => {
      this.renderer.setStyle(contentElement, 'display', 'none');
    });
  }
}
