import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient) {
  }

  public getAllFaq() {
    return this.http.get('/api/faq');
  }

  public getFaqByID(id) {
    return this.http.get(`/api/faq/${id}`);
  }

  public createFaq(faq) {
    return this.http.post(`/api/faq`, faq);
  }

  public updateFaq(faq) {
    return this.http.put(`/api/faq/update/${faq.id}`, {question: faq.question, answer: faq.answer});
  }

  public addTranslation(language: string, question: string, answer: string, id: string) {
    return this.http.put(`/api/faq/translation/${id}`, {
      language: language,
      answer: answer,
      question: question
    });
  }

  public deleteFaq(id) {
    return this.http.delete(`/api/faq/${id}`);
  }
}
