import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor(private http: HttpClient) {
  }

  public getAllAbout() {
    return this.http.get('/api/about');
  }

  public getAboutByID(id) {
    return this.http.get(`/api/about/${id}`);
  }

  public getAboutSvgByID(id) {
    return this.http.get(`/api/about/image/${id}`, {responseType: 'text'});
  }

  public getAboutImageByID(id): Observable<Blob> {
    return this.http.get(`/api/about/image/${id}`, {responseType: 'blob'});
  }

  public createAbout(about) {
    const request = new FormData();

    request.append('image', about.image);
    request.append('text', about.text);

    return this.http.post(`/api/about`, request);
  }

  public addTranslation(language: string, text: string, id: string) {
    return this.http.put(`/api/about/translation/${id}`, {
      language: language,
      text: text
    });
  }

  public updateAbout(about) {
    const request = new FormData();

    request.append('image', about.image);
    request.append('text', about.text);

    return this.http.put(`/api/about/update/${about.id}`, request);
  }

  public deleteAbout(id) {
    return this.http.delete(`/api/about/${id}`);
  }
}
