import {Component, OnDestroy, OnInit} from '@angular/core';
import {ServicesService} from '../../services/services.service';
import {Subscription} from 'rxjs';
import * as rootStore from '../../store/reducers';
import {Store} from '@ngrx/store';
import {PricesService} from '../../services/prices.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {

  servicesList = [];

  language = '';

  private subscriptions: Subscription[] = [];

  constructor(private _services: ServicesService,
              private store: Store<rootStore.State>) {
  }

  ngOnInit() {
    this.loadServiceList();
    this.store.select(rootStore.getCurrentLanguage).subscribe(
      language => {
        this.language = language;
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTextFromTranslation(id: string) {
    return this.servicesList.find(s => s._id === id).translations.find(t => t.language === this.language).text;
  }

  getTitleFromTranslation(id: string) {
    return this.servicesList.find(s => s._id === id).translations.find(t => t.language === this.language).title;
  }

  private loadServiceList() {
    this.subscriptions.push(
      this._services.getAll().subscribe(
        (data: any) => {
          if (data) {
            this.servicesList = [];
            data.forEach(
              (service: any) => {
                service.beforeImage = `/api/services/beforeImage/${service._id}`;
                service.afterImage = `/api/services/afterImage/${service._id}`;
                this.servicesList.push(service);
              }
            );
            this.servicesList.sort(this.compare);
          }
        },
        error1 => {
          console.log(error1);
        }
      )
    );
  }

  private compare(a, b) {
    if (a.urlFragment < b.urlFragment) {
      return -1;
    }
    if (a.urlFragment > b.urlFragment) {
      return 1;
    }
    return 0;
  }
}
